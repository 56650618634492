export default {
    API:{
        CODE_200: 200,
        CODE_202: 202,
        CODE_204: 204,
        CODE_400: 400,
        CODE_404: 404
    },
    ENTITY:{
        FR: `FR`,
        IT: `IT`,
        ES: `ES`,
        DE: `DE`,
        EN: `EN`
    },
    LANGUAGES: {
        FR: "fr-FR",
        EN: "en-GB"
    },
    UNKNOWN: "UNKNOWN",
    UNKNOWN_UID: "com.decathlon.uidUnknown",
    UNKNOWN_TRACK_ID: `070230113107`,
    UNKNOWN_TRACK_ALIAS: `UNKNOWN_TRACK_ALIAS`,
    ERRORS: {
        APP_NOT_FOUND: "No apps found with the name",
        UID_MISSING: "UID missing for the name.",
        EXPECTED_APP_NAME_MISSING: "Some expected application names are missing from the API call.",
        VALUES_NOT_EQUALS: "Test failure: The values are not equals.",
        TEST_FAILED: "The test failed.",
        VALUES_NOT_UPDATED: "The values haven't been updated.",
        EXPECTED_ENTITIES_NOT_PRESENT: "Some expected entities are missing from the API call.",
        CALLER_PERMISSION_ERROR_MSG: "The caller does not have permission",
        CATCHED_ERROR: "An error has occurred but we are continuing the test",
        MSG_VERSION_ALREADY_EXIST: `The version code must be unique.`,
        RESPONSE_CODE_ERROR: `The response code isn't correct`
    },
    INFORMATION: {
        EXPECTED_APP_NAME_PRESENT: "All expected application names are present in the API call.",
        EXPECTED_ENTITIES_PRESENT: "All expected entities are present in the API call.",
        VALUES_EQUALS: "Test passed: Values are equals.",
        VALUES_NOT_EQUALS: "Test passed: Values are not equals.",
        TEST_PASSED: "The test passed.",
        VALUES_UPDATED: "The values have been updated."
    },
    ELEMENTS_PROPERTIES:{
      DISABLED: "disabled"
    },
    DEVICES:{
        ID: "91366",
        SMARTPHONE:{
            ID_01: "186588",
            ID_02: "186597",
            ID_03: "3445",
            ID_04: "180977",
            ID_05: "183581",
            ID_06: "184902",
            ID_07: "183548",
            ID_08: "183338",
            ID_09: "186677",
            ID_10: "186474"
        },
        TABLET:{
            ID_01: "186586",
            ID_02: "186599",
            ID_03: "81620",
            ID_04: "181018"
        },
        KIOSK:{
            ID_01: "186589",
            ID_02: "186600",
            ID_03: "97352",
            ID_04: "186658",
            ID_05: "186741"
        },
        TYPE:{
            SMARTPHONE: "SMARTPHONE",
            TABLET: "TABLET",
            KIOSK: "KIOSK",
        },
        CORPORATE:{
            SERIAL_NUMBER_SMARTPHONE: "zIFOA8q9Ih",
            SERIAL_NUMBER_TABLET: "4SEAZnbD52",
            SERIAL_NUMBER_KIOSK: "Wjzl8Cx5Xz"
        },
        PERSONAL:{
            SERIAL_NUMBER_SMARTPHONE: "WB9bE9T2hd",
            SERIAL_NUMBER_TABLET: "WpxxaE5lXp",
            SERIAL_NUMBER_KIOSK: "Muv22Hj5mX"
        },
        MODELS: {
            SMARTPHONE: {
                MOTO_G6: "moto g(6)",
                MOTO_G6_XPATH: `//div[text()="moto g(6) "]`
            }
        }
    },
    APPLICATIONS:{
        BODY_AAM:{
            USER: `Sam SOULE`,
            MAIL: `sam.soule@decathlon.com`,
            APP_NAME: `SamSouleApp`,
        },
        APK:{
            FILE_APK_APP10: `tests/aab/apk_app10.apk`
        },
        PLAYWRIGHT_TEST:{
            ID: 52495,
            APP_RESPONSIBLE: `Otto Matique`,
            UID: "com.decathlon.playwrightTest",
            TRACK_ID: "production",
            TRACK_ALIAS: "production",
            VERSION_CODE: "1",
            VERSION_CODE_UNKNOWN: "100",
            NAME: "playwrightTest",
            FILE_AAB_V1: `tests/aab/com.decathlon.playwrightTest_1_1695288137021.aab`,
            FILE_PATH_V1: `com.decathlon.playwrightTest_1_1695288137021.aab`
        },
        APPPWCREATIONDELETION1:{
            UID: `com.decathlon.appPwCreationDeletion1`,
            FILE_AAB_V1: `tests/aab/app-appPwCreationDeletion1-V1.aab`,
            FILE_AAB_V2: `tests/aab/app-appPwCreationDeletion1-V2.aab`
        },
        APP1PWVERSION:{
            UID: `com.decathlon.app1PwVersion`,
            FILE_AAB_V1: `tests/aab/app-app1PwVersion-release-V1.aab`,
            FILE_AAB_V2: `tests/aab/app-app1PwVersion-release-V2.aab`
        },
        APP1PWAPPLICATION:{
            UID: `com.decathlon.appPwApplications1`,
            FILE_AAB_V1: `tests/aab/app-appPwApplications1-release.aab`,
        },
        APP2PWAPPLICATION:{
            UID: `com.decathlon.appPwApplications2`,
            FILE_AAB_V1: `tests/aab/app-appPwApplications2-release.aab`,
        },
        APP3PWAPPLICATION:{
            UID: `com.decathlon.appPwApplications3`,
            FILE_AAB_V1: `tests/aab/app-appPwApplications3-release.aab`,
        },
        APP4PWAPPLICATION:{
            UID: `com.decathlon.appPwApplications4`,
            FILE_AAB_V1: `tests/aab/app-appPwApplications4-release.aab`,
        },
        APP5PWAPPLICATION:{
            UID: `com.decathlon.appPwApplications5`,
            FILE_AAB_V1: `tests/aab/app-appPwApplications5-release.aab`,
        },
        APP_TEST_FOR_APP_MANAGER_01:{
            ID: 48795,
            NAME: "App test for AppManager 01"
        },
        SNB: {
            ID: 97,
            PLATFORM: "ANDROID",
            UID: "com.decathlon.inventory",
            DESCRIPTION: "Application mobile pour faire des inventaires.",
            ESSENTIEL: true,
            NAME: "Stock'nBusiness"
        },
        DS: {
            ID: 95,
            PLATFORM: "ANDROID",
            UID: "com.decathlon.android.decathlonservices",
            DESCRIPTION: "Application mobile pour faire des inventaires.",
            ESSENTIEL: true,
            NAME: "Decathlon Services"
        },
        DS2: {
            ID: 51068,
            PLATFORM: "ANDROID",
            UID: "",
            DESCRIPTION: "",
            ESSENTIEL: true,
            NAME: "Decathlon services v2"
        },
        CIS: {
            ID: 113,
            PLATFORM: "ANDROID",
            UID: "com.oxylane.android.cubeinstore",
            DESCRIPTION: "Application mobile pour faire des inventaires.",
            ESSENTIEL: true,
            NAME: "Cube In Store",
            FILE_PLIST: `tests/aab/com.decathlon.cubeInStore_1636022150224.plist`,
        },
        EXP30:{
            NAME: "30 Express"
        },
        AIR_REC:{
            ID: 474,
            NAME: "AirRec"
        },
        APP_TEST_CERB_SERV:{
            ID: 47352,
            NAME: "APP TEST FOR CERBERUS SERVICES"
        },
        SERV_GOOGLE_PLAY:{
            NAME: "Services Google Play",
            UID: "com.google.android.gms",
        },
        CUBE:{
            NAME: "Cube",
            UID: "com.oxylane.android.cubeinstore",
        },
        TROCATHLON:{
            NAME: "Trocathlon",
            UID: "com.decathlon.trocathlon",
        },
        MOBIPRINT:{
            NAME: "Mobiprint",
            UID: "com.oxylane.mobiprint",
        },
        SATIS:{
            NAME: "Satisfaction App",
            UID: "com.wSatisfactionApp_6344510"
        },
        APP_TEST_2:{
            UID: "com.decathlon.applicationtest2",
            APK_SIGNED: `/master/none/release/app-app026-release-bitrise-signed.apk`,
            VERSION_CODE_6: `6`
        },
        APP_TEST_CREATION_AND_DELETION: {
            UID: `com.decathlon.appPwCreationDeletion1`,
            FILE_AAB: `tests/aab/app-appPwCreationDeletion1.aab`,
        },
        APP_TEST_TO_MUCH_GROUPS: {
            ID: 16642,
            UID: `com.decathlon.applicationtest_to_much_group`,
        },
        APP_TEST_GROUPS_1: {
            ID: 52798,
            UID: "com.decathlon.appPwGroups1",
            FILE_AAB: "tests/aab/app-appPwGroups1-release.aab"
        },
        APP_TEST_GROUPS_2: {
            ID: 52802,
            UID: "com.decathlon.appPwGroups2",
            FILE_AAB: "tests/aab/app-appPwGroups2-release.aab"
        },
        APP_TEST_GROUPS_3: {
            ID: 52799,
            UID: "com.decathlon.appPwGroups3",
            FILE_AAB: "tests/aab/app-appPwGroups3-release.aab"
        },
        APP_TEST_GROUPS_4: {
            ID: 52800,
            UID: "com.decathlon.appPwGroups4",
            FILE_AAB: "tests/aab/app-appPwGroups4-release.aab"
        },
        APP_TEST_GROUPS_5: {
            ID: 52803,
            UID: "com.decathlon.appPwGroups5",
            FILE_AAB: "tests/aab/app-appPwGroups5-release.aab"
        },
        APP_TEST_GROUPS_6: {
            ID: 52797,
            UID: "com.decathlon.appPwGroups6",
            FILE_AAB: "tests/aab/app-appPwGroups6-release.aab"
        },
        APP_TEST_GROUPS_7: {
            ID: 52801,
            UID: "com.decathlon.appPwGroups7",
            FILE_AAB: "tests/aab/app-appPwGroups7-release.aab"
        },
        APP_TEST_GROUPS_8: {
            ID: 52844,
            UID: "com.decathlon.appPwGroups8",
            FILE_AAB: "tests/aab/app-appPwGroups8-release.aab"
        },
        APP_TEST_GROUPS_9: {
            ID: 52845,
            UID: "com.decathlon.appPwGroups9",
            FILE_AAB: "tests/aab/app-appPwGroups9-release.aab"
        },
        APP_TEST_GROUPS_10: {
            ID: 52846,
            UID: "com.decathlon.appPwGroups10",
            FILE_AAB: "tests/aab/app-appPwGroups10-release-V1.aab"
        },
        APP_TEST_GROUPS_11: {
            ID: 52847,
            UID: "com.decathlon.appPwGroups11",
            FILE_AAB: "tests/aab/app-appPwGroups11-release-V1.aab"
        },
        APP_TEST_RED_SUITCASE_APK_CERTIFICATE: {
            NAME: "Omnitotem",
            ID: 152,
            UID: "com.decathlon.omnitotem.omnitotem"
        },
        APP_TEST_WITHOUT_DECATHLON_UID: {
            FILE_AAB: "tests/aab/app-appPwWithoutComDecathlon-release.aab"
        },
        QUECHUA_VR_REMOTE: {
            ID: 177,
            NAME: `Quechua VR remote`,
            UID: `com.decathlon.digitallabs.vrremote`,
            VERSION_UPLOADED_EU_AND_CHINA: `1.3`,
            VERSION_NOT_UPLOADED_EU_AND_CHINA: `1.0`
        },
        SLACK:{
            NAME: `Slack`,
            UID: `com.Slack`,
            SELECTOR_BUTTON_HIDDEN: `#application-public-skip-iframe-btn-slack`
        },
        LINKEDIN:{
            NAME: `LinkedIn`,
            UID: `com.linkedin.android`,
            SELECTOR_BUTTON_HIDDEN: `#application-public-skip-iframe-btn-linkedin`
        },
        CHROME:{
            NAME: `Chrome`,
            UID: `com.android.chrome`,
            SELECTOR_BUTTON_HIDDEN: `#application-public-skip-iframe-btn-chrome`
        },
    },

    STORES:{
        CORSICO:{
            ID: `120`
        },
        FENGSHAN:{
            ID: `926`,
        },
        DECATHLON_TAIWAN_OUTLET:{
            ID: `176`
        },
    },

    COUNTRY_CODE:{
        FR: `FR`,
        TW: `TW`
    },

    ENV:{
        PRODUCTION: `production`,
        PREPROD: `preprod`,
        DEV: `dev`
    },

    STATE:{
        DONE: `DONE`,
        ERROR: `ERROR`,
        NONE: `NONE`
    },

    USER_PROFILE:{
        LOGINADMIN_00: `loginAdmin_00`,
        LOGINCP_00: `loginCP_00`,
        LOGINCP_IT_00: `loginCP_IT_00`,
        LOGINCC_00: `loginCC_00`,
        LOGINAPPRESPONSIBLE_00: `loginAppResponsible_00`,
        LOGINCP_SP_00: `loginCP_SP_00`,
        LOGINCP_IN_00: `loginCP_IN_00`,
        LOGINADMIN_01: `loginAdmin_01`,
        LOGINCC_01: `loginCC_01`
    },

    FILE_EXTENSION:{
        APK: ".apk",
        PLIST: ".plist"
    },

    DEVICE_TYPE: {
        SMARTPHONE: "SMARTPHONE",
        KIOSK: `KIOSK`
    },
    BU: {
        RETAIL: "RETAIL",
        SERVICES: "SERVICES"
    }
}
